import React, { useRef, Component, useState } from 'react';
import './Web.scss';
import 'font-awesome/css/font-awesome.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import InputMask from 'react-input-mask';
import config from  './config/config.json';
import { getPrimaryProducts, getSecondaryProducts, postChat } from "./helpers/postChat.js";
import { sendEmail } from "./helpers/sendEmail.js";
import { getClient, usageLimit, getClientProducts, getDiagnosis, postDiagnosis, addClientChat, getConditions, getSymptoms, getBasket, getSymptomsFromOneCondition, getAnamnesisSymptoms, getBasketFromAnamnesis } from "./helpers/getClient.js";
import { createCart, addProductToCart, removeProductFromCart, bulkAddProductToCart, bulkRemoveProductFromCart, addCampaign } from "./helpers/manageCart.js";
import comboImage from './combo-image.png';
import PagueMenosImg from './Pague_menos_1.png';
import IconEnviarImg from './Icon-Enviar.png';
import LOGO_CLINIC_FARMA_2022_2023Img from './LOGO_CLINIC_FARMA_2022_2023.png';
import LOGO_PAGUE_MENOSImg from './LOGO_PAGUE_MENOS.png';
import dipirona1Img from './dipirona1.png';
import dipirona2Img from './dipirona2.webp';
import dipirona3Img from './dipirona3.webp';
import dipirona4Img from './dipirona4.webp';
import tosse1Img from './tosse1.webp';
import tosse2Img from './tosse2.png';
import tosse3Img from './tosse3.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Keyboard from "react-simple-keyboard";
import 'react-simple-keyboard/build/css/index.css';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { TypeAnimation } from 'react-type-animation';
import algoliasearch from "algoliasearch";
import { InstantSearch, SearchBox, Hits, Index } from 'react-instantsearch';
import moment from 'moment'
import 'moment/min/locales';
moment.locale('pt-BR');

const searchClient = algoliasearch("ISYH5F57IK", "056c1036cc9a9d1aad198df8dc09505f");
const index = searchClient.initIndex("balto_prod");

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a href="" ref={ref} onClick={(e) => {
        e.preventDefault();
        onClick(e);
    }}>
    {children}
    </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');
    className = className + " my-custom-dropdown";

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Digite para buscar"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
          )}
        </ul>
      </div>
    );
  },
);

const mockedProducts = require('./helpers/mockedProducts.json');

class Web extends Component {
    constructor(props) {
        super(props);
        this.textLog = React.createRef();
        this.textLog2 = React.createRef();
        let systemContent = 'Finja ser um médico';
        this.myRef = React.createRef()
        this.myRefMobile = React.createRef()
        this.state = {
            hasSelectedSymptom: false,
            firstSymptom: '',
            messages: [
                {"role": "system", "content": systemContent},
                {"role": "assistant", "content": "Olá, sou a Balto e irei te ajudar. Qual o sintoma que mais te incomoda?"}
            ],
            chatStatus: true,
            messageSent: '',
            hasSentIdentifier: true,
            identifier: '',
            hasAddedChat: false,
            hasConsent: true,
            chatId: null,
            showIdentifier: !window.location.search.includes('source=webflow_infomativo'),
            isTyping: false,
            hasSentMessage: false,
            isPlatformWeb: true,
            isRecommendingDoctor: false,
            isRenanTest: false,
            hasRenanSentPrompt: false,
            clientToken: '',
            clientId: 0,
            clientLogoOne: '',
            clientLogoTwo: '',
            isClientInvalid: false,
            isClientDigital: true,
            isClientCustomerFacing: false,
            clientModule: '',
            hasEndedChat: false,
            productsList: [],
            cartList: {},
            cartId: '',
            checkedPrivacy: false,
            needDoctorSymptoms: [],
            finalMessages: [],
            finalMessageIndex: 0,
            startFinalMessage: false,
            secondaryProductsList: [],
            cartTicket: 0,
            combo: {},
            comboInCart: 0,
            isLoadingProductsList: false,
            diagnosis: '',
            showSearchConditionPage: false,
            conditionSearched: '',
            conditionsList: [],
            filteredConditionsList: [],
            productsAdded: {},
            showSeeBasket: false,
            conditionsArray: [],
            selectedConditionsArray: {},
            symptomsArray: [],
            selectedSymptomsArray: {},
            basketArray: [],
            showProducts: {},
            symptomsArrayClean: [],
            hasStartedAnamnese: false,
            hasOneCondition: false,
            mockedAnamnesis: false,
            mockedAnamnesisShowBasket: false,
            showAnamnesisSymptoms: false,
            hasMargin: false,
            hasSearchedSymptoms: false,
            hasLoggedIn: false,
            loginValue: "",
            keyCondition: ""
        };
        this.postChat = this.postChat.bind(this);
        this.removeMessageDelay = this.removeMessageDelay.bind(this);
        // this.selectedSymptom = this.selectedSymptom.bind(this);
        this.inputChatChange = this.inputChatChange.bind(this);
        this.setIdentifier = this.setIdentifier.bind(this);
        this.newChat = this.newChat.bind(this);
        this.sliceMessage = this.sliceMessage.bind(this);
        this.shutDownChat = this.shutDownChat.bind(this);
        this.isRecommendingDoctor = this.isRecommendingDoctor.bind(this);
        this.testeRenan = this.testeRenan.bind(this);
        this.checkClientToken = this.checkClientToken.bind(this);
        this.onEnterPress = this.onEnterPress.bind(this);
        this.handleCart = this.handleCart.bind(this);
        this.onKeyPressKeyboard = this.onKeyPressKeyboard.bind(this);
        this.urlIdentifier = this.urlIdentifier.bind(this);
        this.urlCart = this.urlCart.bind(this);
        this.validateConsent = this.validateConsent.bind(this);
        this.manageCartTicket = this.manageCartTicket.bind(this);
        this.handleComboCart = this.handleComboCart.bind(this);
        this.swipeProduct = this.swipeProduct.bind(this);
        this.searchConditionPage = this.searchConditionPage.bind(this);
        this.searchDiagnosis = this.searchDiagnosis.bind(this);
        this.getProductsFromDiagnosis = this.getProductsFromDiagnosis.bind(this);
        this.Hit = this.Hit.bind(this);
        this.onSubmitAlgolia = this.onSubmitAlgolia.bind(this);
        this.selectedAlogliaButton = this.selectedAlogliaButton.bind(this);
        this.getProductName = this.getProductName.bind(this);
        this.getConditions = this.getConditions.bind(this);
        this.selectCondition = this.selectCondition.bind(this);
        this.getSymptoms = this.getSymptoms.bind(this);
        this.selectSymptom = this.selectSymptom.bind(this);
        this.getBasket = this.getBasket.bind(this);
        this.showProducts = this.showProducts.bind(this);
        this.getSymptomsFromOneCondition = this.getSymptomsFromOneCondition.bind(this);
        this.showNextQuestion = this.showNextQuestion.bind(this);
        this.postLogin = this.postLogin.bind(this);
        
        if (window.location.search.includes('source=teste-renan')) {
            setTimeout(function() {
                this.testeRenan();
            }.bind(this), 300)
        }
    }

    postLogin(e) {
        e.preventDefault();
        if (this.state.clientId == 13) {
            if (this.state.loginValue == 9920 ||
             this.state.loginValue == 19444 ||
             this.state.loginValue == 15912 ||
             this.state.loginValue == 12282) {
                this.setState({
                    hasLoggedIn: true,
                    identifier: this.state.loginValue
                });
            }
        }
        else if (this.state.clientId == 10) {
            if (this.state.loginValue == 2233 ||
                this.state.loginValue == 8710 ||
                this.state.loginValue == 1016 ||
                this.state.loginValue == 1211) {
                this.setState({
                    hasLoggedIn: true,
                    identifier: this.state.loginValue
                });
            }
        }
        else if (this.state.clientId == 14) {
            if (this.state.loginValue == 1234 ||
                this.state.loginValue == 5678) {
                this.setState({
                    hasLoggedIn: true,
                    identifier: this.state.loginValue
                });
            }
        }
    }

    showProducts(e, basketIndex) {
        let showProducts = this.state.showProducts;
        if (showProducts[basketIndex])
            showProducts[basketIndex] = false;
        else
            showProducts[basketIndex] = true;
        this.setState({ showProducts: showProducts });
    }

    getBasket(e) {
        let productsAddedKeys = Object.keys(this.state.productsAdded);
        let products = '';
        for (let i = 0; i < productsAddedKeys.length; i++) {
            products = `${products};${this.state.productsAdded[productsAddedKeys[i]]}`;
        }
        products = products.slice(1);

        let conditions = '';
        let conditionsKeys = Object.keys(this.state.selectedConditionsArray);
        
        for (let i = 0; i < conditionsKeys.length; i++) {
            if (this.state.selectedConditionsArray[conditionsKeys[i]].isChecked)
                conditions = `${conditions},${this.state.selectedConditionsArray[conditionsKeys[i]].condition}`;
        }
        conditions = conditions.slice(1);
        if (conditions == '')
            conditions = '-';
        
        if (this.state.keyCondition != '')
            conditions = this.state.keyCondition;

        let symptoms = '';
        let symptomsKeys = Object.keys(this.state.selectedSymptomsArray);
        
        for (let i = 0; i < symptomsKeys.length; i++) {
            if (this.state.selectedSymptomsArray[symptomsKeys[i]].isChecked)
                symptoms = `${symptoms},${this.state.selectedSymptomsArray[symptomsKeys[i]].symptom}`;
        }
        symptoms = symptoms.slice(1);
        if (symptoms == '')
            symptoms = '-';

        // if (this.state.mockedAnamnesisShowBasket) {
        //     symptoms = 'Febre,Tosse seca,Tosse produtiva,Dor de garganta,Dor de cabeça,Congestão nasal,Infecções frequentes,Dor atrás dos olhos,Ânsia / Vômito,Coceira';
        // }

        // products = '7896212409457,7896523202204,7896422521277,7896004725666,7897076910974,7896004777535';

        if (this.state.getBasketFromAnamnesis) {
            getBasketFromAnamnesis(this.state.clientId, this.state.clientToken, this.state.chatId, symptoms, this.state.identifier, products)
                .then(res => {
                    let combinedFields = res.data[0].combinedFields.split('|||');
                    let products = combinedFields[0].split("*-*");
                    let hasMargin = false;
                    if (products.length > 2)
                        hasMargin = true;

                    this.setState({
                        basketArray: res.data,
                        hasMargin: hasMargin
                    });
                });
        }
        else {
            addClientChat(this.state.clientId, this.state.clientToken, this.state.identifier, products, conditions, symptoms, this.state.chatId)
                .then(res => {
                    return getBasket(this.state.clientId, this.state.clientToken, conditions, symptoms);
                })
                .then(res => {
                    let combinedFields = res.data[0].combinedFields.split('|||');
                    let products = combinedFields[0].split("*-*");
                    let hasMargin = false;
                    if (products.length > 2)
                        hasMargin = true;

                    this.setState({
                        basketArray: res.data,
                        hasMargin: hasMargin
                    });
                });
        }
    }

    getSymptoms(e) {
        let productsAddedKeys = Object.keys(this.state.productsAdded);
        let products = '';
        for (let i = 0; i < productsAddedKeys.length; i++) {
            products = `${products};${this.state.productsAdded[productsAddedKeys[i]]}`;
        }
        products = products.slice(1);
        if (productsAddedKeys.length == 0)
            products = '-';

        let conditions = '';
        let conditionsKeys = Object.keys(this.state.selectedConditionsArray);
        
        for (let i = 0; i < conditionsKeys.length; i++) {
            if (this.state.selectedConditionsArray[conditionsKeys[i]].isChecked)
                conditions = `${conditions},${this.state.selectedConditionsArray[conditionsKeys[i]].condition}`;
        }
        conditions = conditions.slice(1);
        this.setState({ hasSearchedSymptoms: true });
        if (conditions == '') {
            this.setState({ showAnamnesisSymptoms: true });
            getAnamnesisSymptoms(this.state.clientId, this.state.clientToken, products)
                .then(res => {
                    this.setState({
                        symptomsArray: res.data,
                        getBasketFromAnamnesis: true
                    });
                });
        }
        else {
            // products = '7896212409457,7896523202204,7896422521277,7896004725666,7897076910974,7896004777535';

            addClientChat(this.state.clientId, this.state.clientToken, this.state.identifier, products, conditions, '', this.state.chatId)
                .then(res => {
                    // Comentando mock da RD
                    // if (products == '7896181911036,7896181909651,7896714207551,7896422506243,7896422507059,7896714213736,7896004782546,7896714273600,742832304719,7896004769196,7896422506236') {
                    //     this.setState({ mockedAnamnesis: true });
                    //     return {
                    //         data: [
                    //         {
                    //             diagnosis: "",
                    //             symptoms: "Congestão nasal / coriza,Febre,Fadiga extrema (cansaço),Dor de ouvido,Dor nas costas,Dor na cabeça,Dor na barriga,Dor nos membros,Dor de garganta,Dor no rosto,Dor no corpo"
                    //         }]
                    //     };
                    // }
                    // else {
                        return getSymptoms(this.state.clientId, this.state.clientToken, '', conditions);
                    // }
                })
                .then(res => {
                    if (res.data.length == 0) {
                        this.getBasket();
                    }
                    else
                        this.setState({ symptomsArray: res.data });
                });
        }

    }

    showNextQuestion() {
        if (this.state.mockedAnamnesisShowBasket) {
            let isDengue = false;
            let objKey = Object.keys(this.state.selectedSymptomsArray   );

            for (let i = 0; i < objKey.length; i++) {
                if (this.state.selectedSymptomsArray[objKey[i]].symptom == "Náusea e vômito" ||
                    this.state.selectedSymptomsArray[objKey[i]].symptom == "Dor abdominal" ||
                    this.state.selectedSymptomsArray[objKey[i]].symptom == "Dor atrás dos olhos" ||
                    this.state.selectedSymptomsArray[objKey[i]].symptom == "Dor nas articulações / juntas") {
                    isDengue = true;
                    break;
                }
            }
            if (isDengue) {
                this.setState({ diagnosis: "Dengue", selectedConditionsArray: { 0: {
                    isChecked: true,
                    condition: "Dengue"
                } } }, () => {
                    this.getBasket();
                });
            }
            else {
                this.setState({ diagnosis: "Gripe", selectedConditionsArray: { 0: {
                    isChecked: true,
                    condition: "Gripe"
                } } }, () => {
                    this.getBasket();
                });
            }
        }
        else {
            let addAfter = '';
            let keyObj = Object.keys(this.state.selectedSymptomsArray);
            let _symptoms = "Náusea e vômito,Dor abdominal,Dor atrás dos olhos,Alterações na voz,Dor nas articulações / juntas,Tosse";
            let _selectedSymptomsArray = {};

            for (let i = 0; i < keyObj.length; i++) {
                _symptoms = this.state.selectedSymptomsArray[keyObj[i]].symptom + "," + _symptoms;
                _selectedSymptomsArray[`0${i}`] = {
                    isChecked: true,
                    symptom: this.state.selectedSymptomsArray[keyObj[i]].symptom
                };
            }

            let _x = [{
                diagnosis: "",
                symptoms: _symptoms
            }];

            this.setState({
                symptomsArray: _x,
                mockedAnamnesisShowBasket: true,
                selectedSymptomsArray: _selectedSymptomsArray
            });
        }
    }

    selectCondition(e, condition, indexContainer, indexCondition) {
        let selectedConditionsArray = this.state.selectedConditionsArray;
        let key = `${indexContainer}${indexCondition}`;
        let insertedObj = {
            isChecked: (selectedConditionsArray[key] && selectedConditionsArray[key].isChecked)? false : true,
            condition: condition
        };

        selectedConditionsArray[key] = insertedObj;
        this.setState({ selectedConditionsArray: selectedConditionsArray });
    }

    selectSymptom(e, symptom, indexContainer, indexSymptom) {
        let selectedSymptomsArray = this.state.selectedSymptomsArray;
        let key = `${indexContainer}${indexSymptom}`;
        let insertedObj = {
            isChecked: (selectedSymptomsArray[key] && selectedSymptomsArray[key].isChecked)? false : true,
            symptom: symptom
        };

        selectedSymptomsArray[key] = insertedObj;
        this.setState({ selectedSymptomsArray: selectedSymptomsArray });
    }

    getConditions(e) {
        e.preventDefault();

        let productsAddedKeys = Object.keys(this.state.productsAdded);
        let products = this.state.productsAdded[productsAddedKeys[0]];
        for (let i = 1; i < productsAddedKeys.length; i++) {
            products = `${products};${this.state.productsAdded[productsAddedKeys[i]]}`;
        }

        getConditions(this.state.clientId, this.state.clientToken, products)
            .then(res => {
                if (res.data.length >= 1) {
                    let result = [];
                    let uniqueConditionsConcat = [];
                    let hasMoreThanOneCondition = false;

                    for (let j = 0; j < productsAddedKeys.length; j++) {
                        let _conditionsString = '';
                        for (let i = 0; i < res.data.length; i++) {
                            if (String(this.state.productsAdded[productsAddedKeys[j]]).includes(String(res.data[i].groupProductName))) {
                                _conditionsString = _conditionsString + "," + res.data[i].conditions;
                            }
                        }
                        _conditionsString = _conditionsString.slice(1);
                        let _conditionsArray = _conditionsString.split(',');
                        const uniqueConditions = [];
                        const conditionTracker = {};

                        for (let i = 0; i < _conditionsArray.length; i++) {
                            const condition = _conditionsArray[i].trim();

                            if (!conditionTracker[condition]) {
                                uniqueConditions.push(condition);
                                conditionTracker[condition] = true;
                            }
                        }
                        const uniqueConditionsString = uniqueConditions.join(',');
                        uniqueConditionsConcat = uniqueConditionsConcat.concat(uniqueConditions);

                        let obj = {
                            "productName": productsAddedKeys[j],
                            "conditions": uniqueConditionsString
                        };

                        result.push(obj);
                    }
                    const _uniqueElements = new Set(uniqueConditionsConcat);

                    if (_uniqueElements.size == 1) {
                        let _messages = this.state.messages;
                        _messages.push({
                            "role": "assistant",
                            "content": `Para esse(s) medicamento(s) o cliente está com <strong>${res.data[0].conditions}</strong>`
                        });

                        this.setState({
                            messages: _messages,
                            selectedConditionsArray: { 0: {
                                isChecked: true,
                                condition: res.data[0].conditions
                            } },
                            hasOneCondition: true
                        }, () => {
                            this.getSymptomsFromOneCondition(res.data[0].conditions);
                        });
                    }
                    else {
                        this.setState({
                            conditionsArray: result
                        });
                    }

                }
                else {
                    let _messages = this.state.messages;
                    _messages.push({
                        "role": "assistant",
                        "content": "Sinto muito, mas a Balto não está preparada para montar a cesta com esse(s) produto(s) nesse momento"
                    });

                    this.setState({
                        messages: _messages
                    });
                }

            });
    }

    getSymptomsFromOneCondition(condition) {
        getSymptomsFromOneCondition(this.state.clientId, this.state.clientToken, condition)
            .then(res => {
                if (res.data.length == 0) {
                    this.getBasket();
                }
                else
                    this.setState({ symptomsArrayClean: res.data });
            });
    }

    selectedAlogliaButton(event, hit) {
        if (hit.hit.type == 'condition') {
            addClientChat(this.state.clientId, this.state.clientToken, this.state.identifier, '-', hit.hit['search_key_if_type_condition'], '', this.state.chatId)
                .then(res => {
                    let _messages = this.state.messages;
                    _messages.push({
                        "role": "user",
                        "content": `O cliente está com ${hit.hit['text_name']}`
                    });
                    this.setState({
                        chatId: res.data[0],
                        selectedConditionsArray: { 0: {
                            isChecked: true,
                            condition: hit.hit['text_name'],
                        } },
                        diagnosis: hit.hit['text_name'],
                        keyCondition: hit.hit['search_key_if_type_condition'],
                        messages: _messages
                    }, () => {
                        this.getSymptomsFromOneCondition(hit.hit['search_key_if_type_condition']);
                    });
                });
        }
        else if (hit.hit.type == 'product') {
            let products = '';
            let productsAddedKeys = Object.keys(this.state.productsAdded);
            for (let i = 0; i < productsAddedKeys.length; i++) {
                products = `${products};${this.state.productsAdded[productsAddedKeys[i]]}`;
            }

            products = `${products},${hit.hit['groupProductName']}`;
            products = products.slice(1);

            addClientChat(this.state.clientId, this.state.clientToken, this.state.identifier, products, '', '', this.state.chatId)
                .then(res => {
                    let productsAdded = this.state.productsAdded;
                    productsAdded[hit.hit['text_name']] = hit.hit['groupProductName'];

                    let hitResult = hit.hit['text_name'];
                    let _messages = this.state.messages;
                    _messages.push({
                        "role": "user",
                        "content": hitResult.charAt(0).toUpperCase() + hitResult.slice(1).toLowerCase()
                    });

                    let _chatId = this.state.chatId
                    if (_chatId == null)
                        _chatId = res.data[0];

                    this.setState({
                        productsAdded: productsAdded,
                        messages: _messages,
                        showSeeBasket: true,
                        chatId: _chatId
                    });
                });
        }

    }

    async onSubmitAlgolia(event) {
        event.preventDefault();
        const query = event.target[0].value;

        const results = await searchClient
            .initIndex('balto_prod')
            .search(query);

        // const resultsDevProducts = await searchClient
        //     .initIndex('dev_products')
        //     .search(query);

        if (results.hits.length > 0) {
            let hitObj = { hit: results.hits[0] };
            this.selectedAlogliaButton(event, hitObj);
        }
        else {
            this.setState({ hasStartedAnamnese: true });
            this.postChat(event, query);
        }
    }

    getProductName(hit) {
        let hitResult = '';
        if (hit['Nome do produto'])
            return hit['Nome do produto'];
        else
            return hit['name'];
    }

    Hit(hit) {
        let hitResult = this.getProductName(hit.hit);

        return (
            <article className="search-button" onClick={(e) => this.selectedAlogliaButton(e, hit)}>
                <span>{hit.hit['text_name']}</span>
            </article>
        );
    }

    onKeyPressKeyboard(button, isCPF) {
        if (isCPF) {
            if (button == '{bksp}')
                this.setState({ identifier: this.state.identifier.slice(0, -1) });
            else if (button == '{space}')
                this.setState({ identifier: this.state.identifier.concat(' ') });
            else if (button == '{enter}') {
                if (!this.state.checkedPrivacy)
                    alert("Para prosseguir é necessário o aceite da política de privacidade");
                else if (this.state.identifier.length != 11)
                    alert("Preencha corretamente o campo do CPF");
                else
                    this.setIdentifier(false);
            }
            else if (/^\d+$/.test(button) && this.state.identifier.length != 11)
                this.setState({ identifier: this.state.identifier.concat(button) });
        }
        else {
            if (this.state.messageSent == '') {
                this.setState({ messageSent: button });
            }
            else {
                if (button == '{bksp}')
                    this.setState({ messageSent: this.state.messageSent.slice(0, -1) });
                else if (button == '{space}')
                    this.setState({ messageSent: this.state.messageSent.concat(' ') });
                else if (button == '{enter}') {
                    this.postChat();
                    this.setState({ messageSent: '' });
                }
                else
                    this.setState({ messageSent: this.state.messageSent.concat(button) });
            }
        }
    }

    componentDidUpdate() {
        if (this.textLog.current != null)
            this.textLog.current.scrollTop = this.textLog.current.scrollHeight;
    }

    componentDidMount() {
        if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || navigator.userAgent.toLowerCase().indexOf('android') > -1) {
            this.myRefMobile.current.scrollIntoView()
            this.setState({ isPlatformWeb: false })
        }
        this.checkClientToken();
     }

     urlIdentifier() {
        if (window.location.search.includes('identifier=')) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const identifier = urlParams.get('identifier');
            this.setState({
                identifier: identifier,
                hasSentIdentifier: true
            });
        }
     }

     urlCart() {
        if (window.location.search.includes('cartId=')) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const cartId = urlParams.get('cartId');
            this.setState({
                cartId: cartId
            }, () => {
                addCampaign(cartId, this.state.clientToken, this.state.clientModule);
            });
        }
     }

     checkClientToken() {
        const clientToken = window.location.pathname.substring(9);

        getClient(clientToken)
            .then(res => {
                let _messages = [];
                if (res.data[0].id == 1) {
                    _messages = [
                        {"role": "system", "content": "Finja ser um médico"},
                        {"role": "assistant", "content": "Olá, sou a Balto e irei te ajudar."},
                        {"role": "assistant", "content": "Estou aqui para responder suas dúvidas sobre saúde. Como posso ajudar?"}
                    ];
                }
                else {
                    _messages = [
                        {"role": "system", "content": "Finja ser um médico"},
                        {"role": "assistant", "content": "Olá, sou a Balto e irei te ajudar."},
                        {"role": "assistant", "content": "Descreva o que o cliente está sentindo ou selecione os medicamentos que ele está buscando."}
                    ];
                }

                if (res.data[0].initialMessage && res.data[0].initialMessage !== '') {
                    _messages = [
                        {"role": "system", "content": "Finja ser um médico"},
                        {"role": "assistant", "content": res.data[0].initialMessage}
                    ];
                }

                let _finalMessage = (res.data[0].finalMessage)? res.data[0].finalMessage.split('","') : [];

                ReactGA.event({
                    category: "IA conversacional",
                    action: "IA - token cliente validado",
                    label: `IA - token cliente validado ${res.data[0].id}`
                });

                this.setState({
                    clientToken: clientToken,
                    clientId: res.data[0].id,
                    clientLogoOne: (res.data[0].logoOne)? require(`./${res.data[0].logoOne}`) : '',
                    clientLogoTwo: (res.data[0].logoTwo)? require(`./${res.data[0].logoTwo}`) : '',
                    clientModule: res.data[0].searchProductsModule,
                    isClientDigital: res.data[0].isClientDigital,
                    hasConsent: true,
                    hasSentIdentifier: true,
                    messages: _messages,
                    finalMessages: _finalMessage,
                    isClientCustomerFacing: res.data[0].isCustomerFacing
                }, () => {
                    if (!res.data[0].showIdentifier) {
                        this.setState({
                            identifier: 'x',
                            hasSentIdentifier: true
                        });
                    }
                    this.urlIdentifier();
                    this.urlCart();

                    usageLimit(this.state.clientId, this.state.clientToken)
                        .then(res => {
                            if (res.data.hasReachedLimit)
                                this.setState({ isClientInvalid: true });
                        });
                });
            })
            .catch(err => {
                this.setState({ isClientInvalid: true });
            });
     }

     testeRenan() {
        this.setState({
            hasSentIdentifier: true,
            hasConsent: true,
            isRenanTest: true,
            hasRenanSentPrompt: true
        })
     }

    postChat(event, _messageSent) {
        if (event)
            event.preventDefault();

        let newMessages = this.state.messages;
        let messageSent = (this.state.messageSent == '')? _messageSent : this.state.messageSent;
        newMessages.push({"role": "user", "content": messageSent});

        if (this.state.isRenanTest) {
            newMessages = [
                {"role": "system", "content": this.state.messageSent}
            ];
            this.setState({
                isRenanTest: false,
                messages: newMessages
            }, () => {
                this.myRef.current.scrollIntoView()
                this.setState({
                    messageSent: '',
                    isTyping: true
                })
            });

            let obj = {
                client: this.state.clientId,
                identifier: this.state.identifier,
                messages: newMessages,
                chatId: this.state.chatId
            };

            postChat(obj, this.state.clientToken, this.state.clientModule)
                .then(res => {

                    ReactGA.event({
                        category: "IA conversacional",
                        action: "IA - mensagem enviada",
                        label: `IA - conversa com id ${this.state.chatId} - mensagem número ${this.state.messages.length}`
                    });

                    let messages = this.state.messages;
                    let pushObj = {
                        role: res.data.role,
                        content: this.sliceMessage(res.data.content)
                    }
                    let chatId = (this.state.chatId === null)? res.data.chatId : this.state.chatId;
                    
                    if (this.isRecommendingDoctor(res.data.content)) {
                        this.shutDownChat(true, false);
                    }
                    else {
                        messages.push(pushObj);
                        this.setState({
                            messages: messages,
                            chatStatus: true,
                            chatId: chatId,
                            isTyping: false,
                            hasSentMessage: false
                        }, () => {
                            if (res.data.content.includes('xyz') && this.state.clientId != 1) {
                                this.shutDownChat(false, true);
                            }
                            setTimeout(function() {
                                this.myRef.current.scrollIntoView();
                                if (this.textLog1 && this.textLog1.current)
                                    this.textLog1.current.focus();
                            }.bind(this), 300)
                        })
                    }
                });
        }
        else {
            this.setState({
                chatStatus: false,
                messages: newMessages,
                hasSentMessage: true,
                messageSent: '',
                isTyping: true
            }, () => {
                    this.myRef.current.scrollIntoView();

                    let obj = {
                        client: this.state.clientId,
                        identifier: this.state.identifier,
                        messages: newMessages,
                        chatId: this.state.chatId,
                        hasChatEnded: false,
                        hasFinalMessages: false
                    };

                    if (this.state.startFinalMessage) {
                        let pushObj = {
                            role: 'assistant',
                            content: this.state.finalMessages[this.state.finalMessageIndex]
                        }
                        let _messages = newMessages
                        _messages.push(pushObj);
                        obj.hasChatEnded = true;
                        obj.hasFinalMessages = true;

                        if (this.state.finalMessageIndex + 1 == this.state.finalMessages.length) {
                            postChat(obj, this.state.clientToken, this.state.clientModule);
                            this.shutDownChat(false, true);
                        }

                        this.setState({
                            messages: _messages,
                            chatStatus: true,
                            isTyping: false,
                            hasSentMessage: false,
                            finalMessageIndex: this.state.finalMessageIndex + 1
                        });
                    }
                    else {
                        postChat(obj, this.state.clientToken, this.state.clientModule)
                            .then(res => {

                                ReactGA.event({
                                    category: "IA conversacional",
                                    action: "IA - mensagem enviada",
                                    label: `IA - conversa com id ${this.state.chatId} - mensagem número ${this.state.messages.length}`
                                });

                                if (res.data.content != null) {

                                    let messages = this.state.messages;
                                    let pushObj = {
                                        role: res.data.role,
                                        content: this.sliceMessage(res.data.content)
                                    }
                                    let chatId = (this.state.chatId === null)? res.data.chatId : this.state.chatId;

                                    if (res.data.content.includes('xyz') && this.state.finalMessages.length > 0 && this.state.clientId != 1) {
                                        pushObj = {
                                            role: 'assistant',
                                            content: this.state.finalMessages[0]
                                        }
                                        messages.push(pushObj);
                                        this.setState({
                                            messages: messages,
                                            chatStatus: true,
                                            chatId: chatId,
                                            isTyping: false,
                                            hasSentMessage: false,
                                            startFinalMessage: true,
                                            finalMessageIndex: 1
                                        });
                                    }
                                    else {
                                        messages.push(pushObj);
                                        this.setState({
                                            messages: messages,
                                            chatStatus: true,
                                            chatId: chatId,
                                            isTyping: false,
                                            hasSentMessage: false
                                        }, () => {
                                            if (res.data.diagnosis){
                                                ReactGA.event({
                                                    category: "IA conversacional",
                                                    action: "IA - finalizou a conversa",
                                                    label: `IA - conversa com id ${this.state.chatId}`
                                                });

                                                if (this.state.finalMessages.length > 0) {
                                                    let _messages = this.state.messages;
                                                    messages.push({"role": "system", "content": this.state.finalMessages[0]})
                                                    this.setState({
                                                        messages: _messages,
                                                        finalMessageIndex: 1
                                                    });
                                                }
                                                else {
                                                    this.setState({ isLoadingProductsList: true },
                                                        () => {
                                                            this.shutDownChat(false, true);
                                                            getPrimaryProducts(this.state.clientId, this.state.clientModule, res.data.chatId)
                                                                .then(_res => {
                                                                    if (_res.data.length == 0 && !_res.data.price) {
                                                                        let lastMessages = this.state.messages;
                                                                        lastMessages.pop();
                                                                        lastMessages.push({
                                                                            role: 'assistant',
                                                                            content: '<a href='
                                                                        })

                                                                        this.setState({
                                                                            isRecommendingDoctor: true
                                                                        });
                                                                    }
                                                                    else {
                                                                        if (_res.data.price) {
                                                                            messages.pop();
                                                                            messages.push({
                                                                                role: 'assistant',
                                                                                content: 'Certo, estamos finalizando o chat.'
                                                                            },{
                                                                                role: 'assistant',
                                                                                content: 'Aqui estão os produtos ideiais com os melhores preços.'
                                                                            });

                                                                            this.setState({
                                                                                combo: _res.data,
                                                                                messages: messages,
                                                                                isLoadingProductsList: false
                                                                            })
                                                                        }
                                                                        else {
                                                                            let needDoctorSymptoms = [];
                                                                            for (let i = 0; i < _res.data.length; i++) {
                                                                                if (_res.data[i].products[0] == 'Procurar médico') {
                                                                                    needDoctorSymptoms.push(_res.data[i].symptom)
                                                                                }
                                                                            }

                                                                            if (needDoctorSymptoms.length == _res.data.length && _res.data.length != 0) {
                                                                                let lastMessages = this.state.messages;
                                                                                lastMessages.pop();
                                                                                lastMessages.push({
                                                                                    role: 'assistant',
                                                                                    content: '<a href='
                                                                                })

                                                                                this.setState({
                                                                                    isRecommendingDoctor: true
                                                                                });
                                                                            }

                                                                            else {
                                                                                messages.pop();
                                                                                
                                                                                
                                                                                if(this.state.clientId == 11) {
                                                                                    messages.push({
                                                                                        role: 'appointmentpoc',
                                                                                        content: ''
                                                                                    },{
                                                                                        role: 'assistant',
                                                                                        content: 'Certo, estamos finalizando o chat.'
                                                                                    },{
                                                                                        role: 'assistant',
                                                                                        content: 'Além da consulta médica, para você se sentir melhor logo, aqui estão os medicamentos que mais fazem sentido para você.'
                                                                                    },{
                                                                                        role: 'assistant',
                                                                                        content: 'O uso de medicação pode trazer riscos, dentre eles alergias e eventos adversos. Procure sempre o médico e o farmacêutico. Leia a bula. Se persistirem os sintomas, o médico deverá ser consultado.'
                                                                                    });
                                                                                }
                                                                                else {
                                                                                    messages.push({
                                                                                        role: 'assistant',
                                                                                        content: 'Certo, estamos finalizando o chat.'
                                                                                    },{
                                                                                        role: 'assistant',
                                                                                        content: 'Além da consulta médica, para você se sentir melhor logo, aqui estão os medicamentos que mais fazem sentido para você.'
                                                                                    },{
                                                                                        role: 'assistant',
                                                                                        content: 'O uso de medicação pode trazer riscos, dentre eles alergias e eventos adversos. Procure sempre o médico e o farmacêutico. Leia a bula. Se persistirem os sintomas, o médico deverá ser consultado.'
                                                                                    });
                                                                                }

                                                                                this.setState({
                                                                                    productsList: _res.data,
                                                                                    needDoctorSymptoms: needDoctorSymptoms,
                                                                                    messages: messages,
                                                                                    isLoadingProductsList: false
                                                                                });

                                                                                // getSecondaryProducts(this.state.chatId, this.state.clientModule)
                                                                                //     .then(res => {
                                                                                //         this.setState({
                                                                                //             secondaryProductsList: res.data
                                                                                //         });
                                                                                //     });
                                                                            }
                                                                        }
                                                                    }
                                                                })
                                                                .catch(err => {
                                                                    let lastMessages = this.state.messages;
                                                                    lastMessages.push({
                                                                        role: 'assistant',
                                                                        content: '<a href='
                                                                    })

                                                                    this.setState({
                                                                        isRecommendingDoctor: true
                                                                    });
                                                                });
                                                            if (this.state.cartId == '') {
                                                                createCart(this.state.clientToken, this.state.clientModule)
                                                                    .then(_res => {
                                                                        this.setState({ cartId: _res.data.orderFormId })
                                                                    })
                                                            }
                                                        });
                                                }
                                            }

                                            else if (res.data.content.includes('xyz') && this.state.clientId != 1) {
                                                ReactGA.event({
                                                    category: "IA conversacional",
                                                    action: "IA - chegou mensagem final",
                                                    label: `IA - conversa com id ${this.state.chatId}`
                                                });

                                                if (this.state.finalMessages.length > 0) {
                                                    let _messages = this.state.messages;
                                                    messages.push({"role": "system", "content": this.state.finalMessages[0]})
                                                    this.setState({
                                                        messages: _messages,
                                                        finalMessageIndex: 1
                                                    });
                                                }
                                                else {
                                                    this.shutDownChat(false, true);
                                                    obj.hasChatEnded = true;

                                                    this.setState({ isLoadingProductsList: true }, 
                                                        () => {
                                                            postChat(obj, this.state.clientToken, this.state.clientModule)
                                                                .then(_res => {
                                                                    if (this.state.isClientDigital) {
                                                                        if (_res.data.length == 0 && !_res.data.price) {
                                                                            let lastMessages = this.state.messages;
                                                                            lastMessages.push({
                                                                                role: 'assistant',
                                                                                content: '<a href='
                                                                            })

                                                                            this.setState({
                                                                                isRecommendingDoctor: true,
                                                                                isLoadingProductsList: false
                                                                            });
                                                                        }
                                                                        else {
                                                                            if (_res.data.price) {
                                                                                messages.pop();
                                                                                messages.push({
                                                                                    role: 'assistant',
                                                                                    content: 'Certo, estamos finalizando o chat.'
                                                                                },{
                                                                                    role: 'assistant',
                                                                                    content: 'Aqui estão os produtos ideiais com os melhores preços.'
                                                                                });

                                                                                this.setState({
                                                                                    combo: _res.data,
                                                                                    messages: messages,
                                                                                    isLoadingProductsList: false
                                                                                })
                                                                            }
                                                                            else {
                                                                                let needDoctorSymptoms = [];
                                                                                for (let i = 0; i < _res.data.length; i++) {
                                                                                    if (_res.data[i].products[0] == 'Procurar médico') {
                                                                                        needDoctorSymptoms.push(_res.data[i].symptom)
                                                                                    }
                                                                                }

                                                                                if (needDoctorSymptoms.length == _res.data.length && _res.data.length != 0) {
                                                                                    let lastMessages = this.state.messages;
                                                                                    lastMessages.push({
                                                                                        role: 'assistant',
                                                                                        content: '<a href='
                                                                                    })

                                                                                    this.setState({
                                                                                        isRecommendingDoctor: true,
                                                                                        isLoadingProductsList: false
                                                                                    });
                                                                                }

                                                                                else {
                                                                                    if(this.state.clientId == 11) {
                                                                                        messages.push({
                                                                                        role: 'appointmentpoc',
                                                                                        content: ''
                                                                                        },{
                                                                                            role: 'assistant',
                                                                                            content: 'Além da consulta médica, para você se sentir melhor logo, aqui estão os medicamentos que mais fazem sentido para você.'
                                                                                        },{
                                                                                            role: 'assistant',
                                                                                            content: 'O uso de medicação pode trazer riscos, dentre eles alergias e eventos adversos. Procure sempre o médico e o farmacêutico. Leia a bula. Se persistirem os sintomas, o médico deverá ser consultado.'
                                                                                        });
                                                                                    }
                                                                                    else {
                                                                                        messages.push({
                                                                                            role: 'assistant',
                                                                                            content: 'Certo, estamos finalizando o chat.'
                                                                                        },{
                                                                                            role: 'assistant',
                                                                                            content: 'Além da consulta médica, para você se sentir melhor logo, aqui estão os medicamentos que mais fazem sentido para você.'
                                                                                        },{
                                                                                            role: 'assistant',
                                                                                            content: 'O uso de medicação pode trazer riscos, dentre eles alergias e eventos adversos. Procure sempre o médico e o farmacêutico. Leia a bula. Se persistirem os sintomas, o médico deverá ser consultado.'
                                                                                        });
                                                                                    }

                                                                                    this.setState({
                                                                                        productsList: _res.data,
                                                                                        needDoctorSymptoms: needDoctorSymptoms,
                                                                                        messages: messages,
                                                                                        isLoadingProductsList: false
                                                                                    });

                                                                                    // getSecondaryProducts(this.state.chatId, this.state.clientModule)
                                                                                    //     .then(res => {
                                                                                    //         this.setState({
                                                                                    //             secondaryProductsList: res.data,
                                                                                    //             isLoadingProductsList: false
                                                                                    //         });
                                                                                    //     });
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        let _diagnosisArray = (_res.data.diagnosis).split(', ');
                                                                        let _diagnosis = {
                                                                            0: {
                                                                                isChecked: true,
                                                                                condition: _diagnosisArray[0]
                                                                            },
                                                                            1: {
                                                                                isChecked: true,
                                                                                condition: _diagnosisArray[1]
                                                                            },
                                                                            2: {
                                                                                isChecked: true,
                                                                                condition: _diagnosisArray[2]
                                                                            }
                                                                        };
                                                                        
                                                                        this.setState({
                                                                            selectedConditionsArray: _diagnosis,
                                                                            isLoadingProductsList: false
                                                                        }, () => {
                                                                            this.getBasket();
                                                                        });


                                                                        // getClientProducts(this.state.clientId, _res.data.diagnosis)
                                                                        //     .then(res1 => {
                                                                                
                                                                        //         if(this.state.clientId == 11) {
                                                                        //             messages.push({
                                                                        //                 role: 'appointmentpoc',
                                                                        //                 content: ''
                                                                        //             },{
                                                                        //                 role: 'assistant',
                                                                        //                 content: 'Além da consulta médica, para você se sentir melhor logo, aqui estão os medicamentos que mais fazem sentido para você.'
                                                                        //             },{
                                                                        //                 role: 'assistant',
                                                                        //                 content: 'O uso de medicação pode trazer riscos, dentre eles alergias e eventos adversos. Procure sempre o médico e o farmacêutico. Leia a bula. Se persistirem os sintomas, o médico deverá ser consultado.'
                                                                        //             });
                                                                        //         }
                                                                        //         else {
                                                                        //             messages.push({
                                                                        //                 role: 'assistant',
                                                                        //                 content: 'Além da consulta médica, para você se sentir melhor logo, aqui estão os medicamentos que mais fazem sentido para você.'
                                                                        //             },{
                                                                        //                 role: 'assistant',
                                                                        //                 content: 'O uso de medicação pode trazer riscos, dentre eles alergias e eventos adversos. Procure sempre o médico e o farmacêutico. Leia a bula. Se persistirem os sintomas, o médico deverá ser consultado.'
                                                                        //             });
                                                                        //         }

                                                                        //         this.setState({
                                                                        //             diagnosis: res1.data.diagnoses,
                                                                        //             productsList: res1.data.products,
                                                                        //             messages: messages,
                                                                        //             isLoadingProductsList: false
                                                                        //         });
                                                                        //     });
                                                                    }
                                                                })
                                                                .catch(err => {
                                                                    let lastMessages = this.state.messages;
                                                                    lastMessages.push({
                                                                        role: 'assistant',
                                                                        content: '<a href='
                                                                    })

                                                                    this.setState({
                                                                        isRecommendingDoctor: true
                                                                    });
                                                                });


                                                            if (this.state.cartId == '') {
                                                                createCart(this.state.clientToken, this.state.clientModule)
                                                                    .then(_res => {
                                                                        this.setState({ cartId: _res.data.orderFormId })
                                                                    })
                                                            }
                                                        });


                                                }
                                            }
                                            else {
                                                this.myRef.current.scrollIntoView();
                                                if (this.textLog1 && this.textLog1.current)
                                                    this.textLog1.current.focus();
                                            }
                                        })
                                    }
                                }
                            });
                    }
                })
        }
    }

    removeMessageDelay() {
        setTimeout(function() {
            this.setState({contactStatus: 0})
        }.bind(this), 4000)
    }

    setIdentifier(event) {
        if (event)
            event.preventDefault();

        if (!this.state.identifier.includes('_') && this.state.identifier !== '' && (this.state.identifier.length == 11 || this.state.identifier.length == 14))
            this.setState({ hasSentIdentifier: true });
    }

    inputPrivacyChange(e) {
        this.setState({ checkedPrivacy: e.target.checked });
    }

    inputChatChange(e, key) {
        this.setState({ [key]: e.target.value });
    }

    onEnterPress(e) {
        if(e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            this.postChat(e);
        }
    }

    newChat() {
        this.setState({
            hasSelectedSymptom: false,
            firstSymptom: '',
            messages: [],
            chatStatus: true,
            messageSent: '',
            hasSentIdentifier: false,
            identifier: '',
            hasAddedChat: false,
            hasConsent: false
        });
    }

    isRecommendingDoctor(lastMessage) {
        if (lastMessage.includes('consulta')) {
            if (lastMessage.includes('marcar') || lastMessage.includes('agend') || lastMessage.includes('prefer'))
                return true;
        }
        if (lastMessage.includes('indi')) {
            if (lastMessage.includes('profissional') || lastMessage.includes('clínica') || lastMessage.includes('dentista') || (lastMessage.includes('próximo') && lastMessage.includes('você')) || lastMessage.includes('médico'))
                return true;
        }
        if ( (lastMessage.includes('recomenda') && lastMessage.includes('profissional')) || 
            (lastMessage.includes('sugerir') && lastMessage.includes('médico')) || 
            (lastMessage.includes('procura') && lastMessage.includes('opção') && lastMessage.includes('teleconsulta'))
            )
                return true;
    }

    sliceMessage(message) {
        if (message.includes('xyz.'))
            return message.slice(0, message.lastIndexOf('.', message.lastIndexOf('.')-1))
        if (message.includes('xyz'))
            return message.slice(0, message.lastIndexOf('.'));
        return message;
    }

    shutDownChat(isRecommendingDoctor, hasEndedChat) {
        let newMessages = this.state.messages;
        if(!this.state.hasRenanSentPrompt) {
            // newMessages.push({
            //     role: 'assistant',
            //     content: 'Precisou de médico?'
            // },{
            //     role: 'assistant',
            //     content: '<a href='
            // })

            // Alterar layout quando chatStatus estiver igual a false
            this.setState({
                chatStatus: false,
                isRecommendingDoctor: isRecommendingDoctor,
                hasEndedChat: hasEndedChat
            });
        }
    }

    handleCart(index, productPrice, productId, addingProduct) {
        let vtexIndex = 0;
        let _cartList = this.state.cartList;
        let cartListObj = {
            quantity: 0,
            cartIndex: 0
        }

        if (addingProduct) {

            ReactGA.event({
                category: "IA conversacional",
                action: "IA - adicionou produto no carrinho",
                label: `IA - id do produto ${productId} - id do carrinho ${this.state.cartId}`
            });

            ReactGA.event({
                category: "IA conversacional",
                action: `IA - adicionou produto no carrinho na posição ${index}`
            });

            if (_cartList[index]) {
                cartListObj.quantity = _cartList[index].quantity + 1;
                cartListObj.cartIndex = _cartList[index].cartIndex;
            }
            else {
                cartListObj.quantity = 1;
                cartListObj.cartIndex = Object.keys(_cartList).length;
            }

            this.manageCartTicket(productPrice, true)

            addProductToCart(this.state.cartId, cartListObj.quantity, productId, this.state.clientToken, this.state.clientModule)
                .then(res => {
                    _cartList[index] = cartListObj;
                    this.setState({ 'cartList': _cartList });
                });
        }
        else {

            ReactGA.event({
                category: "IA conversacional",
                action: "IA - removeu produto no carrinho",
                label: `IA - id do produto ${productId} - id do carrinho ${this.state.cartId}`
            });

            this.manageCartTicket(productPrice, false)

            removeProductFromCart(_cartList[index].cartIndex, _cartList[index].quantity - 1, this.state.cartId, this.state.clientToken, this.state.clientModule)
                .then(res => {
                    _cartList[index].quantity = _cartList[index].quantity - 1;
                    this.setState({ 'cartList': _cartList });
                });
        }
    }

    validateConsent() {
        if (this.state.checkedPrivacy) {
            if (this.state.clientId != 11)
                this.setState({
                    hasConsent: true,
                    hasSentIdentifier: true
                }, () => {
                    if (this.textLog1 && this.textLog1.current)
                        this.textLog1.current.focus()
                });

            if (this.state.clientId == 11 && this.state.identifier != '')
                this.setState({
                    hasConsent: true,
                    hasSentIdentifier: true
                }, () => {
                    if (this.textLog1 && this.textLog1.current)
                        this.textLog1.current.focus()
                });
        }
    }

    manageCartTicket(price, isAdding) {
        let cartTicket = (isAdding)? this.state.cartTicket + price : this.state.cartTicket - price;
        this.setState({ cartTicket: cartTicket });
    }

    handleComboCart(isAdding) {
        let fetchArray = [];
        let orderItems = [];

        if (isAdding) {
            ReactGA.event({
                category: "IA conversacional",
                action: "IA - adicionou combo no carrinho"
            });

            for (let i = 0; i < this.state.combo.skus.length; i++) {
                let obj = {
                    "id": this.state.combo.skus[i],
                    "quantity": this.state.comboInCart + 1,
                    "seller": "1"
                };
                orderItems.push(obj);
            }

            bulkAddProductToCart(this.state.cartId, orderItems, this.state.clientToken, this.state.clientModule)
                .then(res => {
                    this.setState({ comboInCart: this.state.comboInCart + 1 });
                });
        }
        else {
            ReactGA.event({
                category: "IA conversacional",
                action: "IA - removeu combo no carrinho"
            });

            for (let i = 0; i < this.state.combo.skus.length; i++) {
                let obj = {
                    "quantity": this.state.comboInCart - 1,
                    "index": i
                };
                orderItems.push(obj);
            }

            bulkRemoveProductFromCart(orderItems, this.state.cartId, this.state.clientToken, this.state.clientModule)
                .then(res => {
                    this.setState({ comboInCart: this.state.comboInCart - 1 });
                });
        }
    }

    swipeProduct(obj, isPrimary, carousselLineIndex) {
        let primaryText = isPrimary? 'primario' : 'secundario';

        ReactGA.event({
            category: "IA conversacional",
            action: `IA - swipe produto ${primaryText} na linha ${carousselLineIndex} indice ${obj.activeIndex}`
        });
    }

    searchConditionPage() {
        getDiagnosis(this.state.clientId, this.state.clientToken)
            .then(res => {
                this.setState({
                    showSearchConditionPage: true,
                    filteredConditionsList: res.data,
                    conditionsList: res.data
                })
            });
    }

    searchDiagnosis(e) {
        let finalArray = [];
        for (let i = 0; i < this.state.conditionsList.length; i++) {
            if (this.state.conditionsList[i].diagnosis.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0)
                finalArray.push(this.state.conditionsList[i]);
        }
        this.setState({ filteredConditionsList: finalArray })
    }

    getProductsFromDiagnosis(index) {
        getClientProducts(this.state.clientId, this.state.filteredConditionsList[index].diagnosis)
            .then(res => {
                this.setState({
                    productsList: res.data.products,
                    diagnosis: res.data.diagnoses
                });
            });

        postDiagnosis(this.state.clientId, this.state.clientToken, this.state.filteredConditionsList[index].diagnosis, this.state.identifier);
    }

    render() {
        const basketElements = [];
        let productsInsideBasket = [];
        let previousDiagnosis = null;

        if (this.state.diagnosis == "Dengue" && this.state.mockedAnamnesis) {
            productsInsideBasket.push(
                <tr className="show-product">
                    <td className="product-name"><div className="basket-designation">Para rastrear dengue</div>PESQUISA DE ANTICORPOS IGM E IGC E ANTÍGENO NS1</td>
                    <td className="product-price">R$ 43,71 <div className="product-margin"></div></td>
                    <td className="product-see-all"></td>
                </tr>
            );
        }

        this.state.basketArray.forEach((basket, basketIndex) => {
            if (basket.diagnosis !== previousDiagnosis && basketIndex > 0) {
                basketElements.push(
                    <div id={"basket-container-" + basketIndex} key={basketIndex} className='basket-container'>
                        <div className="diagnosis-text-container">Cesta ideal dado que o cliente
                            {(this.state.hasStartedAnamnese)?
                                <span> pode ter:</span>
                                : <span> tem:</span>
                            }
                            <span className="diagnosis-text">{previousDiagnosis}</span></div>
                        <Table className="product-container">
                            <thead>
                                <tr>
                                    <th>Nome do produto</th>
                                    <th>Preço 
                                    </th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productsInsideBasket}
                            </tbody>
                        </Table>
                    </div>
                );
                productsInsideBasket = [];
            }
            else if (basketIndex === this.state.basketArray.length - 1) {
                basketElements.push(
                    <div id={"basket-container-" + basketIndex} key={basketIndex} className='basket-container'>
                        <div className="diagnosis-text-container">Cesta ideal dado que o cliente tem: <span className="diagnosis-text">
                            {(this.state.keyCondition == '')?
                                basket.diagnosis
                                : this.state.diagnosis
                            }
                        </span></div>
                        <Table className="product-container">
                            <thead>
                                <tr>
                                    <th>Nome do produto</th>
                                    <th>Preço 
                                    </th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productsInsideBasket}
                            </tbody>
                        </Table>
                    </div>
                );
            }

            basket.combinedFields.split("|||").forEach((product, productIndex) => {
                if (productIndex <= 5) {
                    let parts = product.split("*-*");
                    if (this.state.mockedAnamnesis) {
                        if (basket.designation != "Para diminuir a inflamação" && basket.designation != "Para acabar com a dor atrás dos olhos" && basket.designation != "Para acabar com a dor de cabeça" && basket.designation != "Para cortar a ânsia e o vômito" && basket.designation != "Para reidratar e repor sais minerais" && basket.designation != "Para acabar com a dor de cabeça" && basket.designation != "Para aliviar a dor" && basket.designation != "Para aliviar a tosse" && basket.designation != "Para fazer inalação") {
                            productsInsideBasket.push(
                                <tr key={`product-${basketIndex}-${productIndex}`} className={(productIndex == 0 || this.state.showProducts[basketIndex])? (productIndex == 0)? "show-product" : "show-product background-blue" : 'hide-product'}>
                                    <td className="product-name"><div className="basket-designation">{basket.designation}</div>{parts[0]}</td>
                                    <td className="product-price">R$ {parts[1]} 
                                    </td>
                                    <td className="product-see-all" onClick={(e) => this.showProducts(e, basketIndex)}>{(productIndex == 0)? (this.state.showProducts[basketIndex])? "Retrair" : "Expandir" : ""}</td>
                                </tr>
                            );
                        }
                    }
                    else {
                        productsInsideBasket.push(
                            <tr key={`product-${basketIndex}-${productIndex}`} className={(productIndex == 0 || this.state.showProducts[basketIndex])? (productIndex == 0)? "show-product" : "show-product background-blue" : 'hide-product'}>
                                <td className="product-name"><div className="basket-designation">{basket.designation}</div>{parts[0]}</td>
                                <td className="product-price">R$ {parts[1]} 
                                </td>
                                <td className="product-see-all" onClick={(e) => this.showProducts(e, basketIndex)}>{(productIndex == 0)? (this.state.showProducts[basketIndex])? "Retrair" : "Expandir" : ""}</td>
                            </tr>
                        );
                    }
                }
            });

            previousDiagnosis = basket.diagnosis;
        });

        if (this.state.diagnosis == "Dengue" && this.state.mockedAnamnesis) {
            productsInsideBasket.push(
                <tr className="show-product">
                    <td className="product-name"><div className="basket-designation">Para medir a temperatura</div>TERMÔMETRO DIGITAL CLÍNICO G-TECH BRANCO</td>
                    <td className="product-price">R$ 19,90 <div className="product-margin">(R$ 11.20)</div></td>
                    <td className="product-see-all"></td>
                </tr>
            );
            productsInsideBasket.push(
                <tr className="show-product">
                    <td className="product-name"><div className="basket-designation">Cuidados gerais</div>Orientar a tomar vacina da dengue em 2 semanas</td>
                    <td className="product-price"><div className="product-margin"></div></td>
                    <td className="product-see-all"></td>
                </tr>
            );
        }
        if (this.state.diagnosis == "Gripe" && this.state.mockedAnamnesis) {
            productsInsideBasket.push(
                <tr className="show-product">
                    <td className="product-name"><div className="basket-designation">Para medir a temperatura</div>TERMÔMETRO DIGITAL CLÍNICO G-TECH BRANCO</td>
                    <td className="product-price">R$ 19,90 <div className="product-margin">(R$ 11.20)</div></td>
                    <td className="product-see-all"></td>
                </tr>
            );
            productsInsideBasket.push(
                <tr className="show-product">
                    <td className="product-name"><div className="basket-designation">Cuidados gerais</div>Orientar a tomar vacina da gripe em 2 semanas</td>
                    <td className="product-price"><div className="product-margin"></div></td>
                    <td className="product-see-all"></td>
                </tr>
            );
        }

        return (
            <div>
                <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>
                {this.state.isClientInvalid?
                    <div className='first-container'>
                        <div className='invalid-token-container'>
                            <div className='invalid-token'>Token inválido</div>
                        </div>
                        <div className="logo-container">
                            <div className="powered-by-container">
                                <div className="powered-by">
                                    <span className="cnpj">Powered by</span>
                                    <span>Balto</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    <section className={(this.state.isClientInvalid)? 'display-none' : 'first-container'}>
                        {(this.state.messages.length > 3)?
                            <div className="new-chat">
                                <button onClick={() => window.location.reload()}>Nova conversa</button>
                            </div>
                            : ""
                        }
                        <div className={(this.state.isPlatformWeb) ? '' : 'mobile-class'}>
                            <div className={(!this.state.hasConsent)? ((!this.state.isClientDigital && this.state.hasConsent)? 'identifier-container identifier-container-key-board' : 'identifier-container'): 'display-none'}>
                                {this.state.showIdentifier? 
                                    <h2>Esta é uma anamnese farmacêutica* e não substitui uma consulta médica.</h2>
                                    : <h2>Este serviço serve para auxiliar seu médico, e não substitui a consulta.</h2>
                                }
                                <div className='explanation-anamnese'>*coleta de dados sobre o paciente através de perguntas.</div>

                                <div className={(!this.state.hasSentIdentifier) ?  'identifier-container-phone' : 'display-none'}>
                                    <h2>Telefone do cliente</h2>
                                    <div className="identifier-container-main">
                                        <Form onSubmit={(e) => this.setIdentifier(e)} className="identifier-form">
                                            <Form.Group controlId="identifier" className="identifier-input">
                                                <InputMask
                                                    onChange={(e) => this.inputChatChange(e, 'identifier')}
                                                    placeholder="(99) 99999-9999"
                                                    autoFocus
                                                    value={this.state.identifier}
                                                    mask='(99) 99999-9999'>
                                                </InputMask>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                                <div className='checkbox-privacy-policy'>
                                    <Form.Check 
                                        required
                                        onChange={(e) => this.inputPrivacyChange(e)}
                                        value={this.state.checkedPrivacy}
                                        type='checkbox'
                                        id='checkbox'
                                    />
                                    <div className='terms-of-use-text'>Eu concordo com os <a target="_blank" href="/termos-de-uso">termos de uso</a> e desejo continuar.</div>
                                </div>
                                <Button onClick={() => this.validateConsent()} className="identifier-button" type="submit"><strong>CONTINUAR</strong></Button>
                                <div className="powered-by">
                                    <span className="cnpj"><i>powered by</i></span>
                                    <span>BALTO</span>
                                </div>
                            </div>


                            <div className={(this.state.hasLoggedIn) ? 'display-none' : 'identifier-container login-page mobile-class'}>
                                <div className="login-container">
                                    <div className="login-text">Insira sua senha para acesso</div>
                                    <Form onSubmit={(e) => this.postLogin(e)} className="login-form">
                                        <Form.Group controlId="login" className="inline-input">
                                            <Form.Control
                                                ref={this.textLog2}
                                                className="login-input"
                                                onChange={(e) => this.inputChatChange(e, 'loginValue')}
                                                autoFocus
                                                value={this.state.loginValue} />
                                        </Form.Group>
                                    </Form>
                                    <Button onClick={(e) => this.postLogin(e)} className="identifier-button" type="submit"><strong>CONTINUAR</strong></Button>
                                    <div className="powered-by">
                                        <span className="cnpj"><i>powered by</i></span>
                                        <span>BALTO</span>
                                    </div>
                                </div>
                            </div>
                        

                        </div>
                        <div className={(this.state.isPlatformWeb) ? '' : 'mobile-class'}>
                            <div className={(this.state.hasSentIdentifier && this.state.hasConsent)? 'display-none' : (!this.state.isClientDigital && this.state.hasConsent)? 'logo-container logo-container-key-board' : 'logo-container'}>
                                <div className="powered-by-container">
                                    <img className="display-none" src={this.state.clientLogoTwo} />
                                    <img className="clinic-farma" src={this.state.clientLogoOne} />
                                </div>
                                <div>
                                    <img src={this.state.clientLogoTwo} />
                                    <span className="cnpj display-none">CNPJ 48.554.329/0001-20</span>
                                </div>
                                <span className="space display-none">E</span>
                            </div>
                        </div>

                        <div className={(this.state.showSearchConditionPage)? 'display-none' : (((this.state.hasSentIdentifier && this.state.hasConsent && this.state.hasLoggedIn) || (this.state.hasConsent && !this.state.showIdentifier))? '' : 'display-none')}>
                            <div className={(this.state.isPlatformWeb) ? '' : 'mobile-class'}>
                                <div className={(this.state.hasEndedChat) ? 'exchanging-messages-ended' : ((this.state.isClientDigital)? 'exchanging-messages' : 'exchanging-messages exchanging-messages-non-digital')}>
                                    {this.state.messages.slice(1, this.state.messages.length).map((message, index) =>
                                        <div id={"item-" + index} key={index} className={"message-pattern message-type-" + message.role}>
                                            {(message.role === 'user')?
                                                <strong>Você:  </strong>
                                                : 
                                                (message.role == 'appointmentpoc')?
                                                    ""
                                                    : <strong>Balto:  </strong>
                                            }
                                            {(message.content == '<a href=')?
                                                <span>Dado o seu contexto de saúde é importante que você realize uma consulta médica. O <a onClick={() => ReactGA.event({ category: "IA conversacional", action: "IA - clicou agendar consulta", label: "IA - clicou agendar consulta" })} href="https://semprebem.ciclic.com.br/?utm_source=balto" target="_blank">Sempre Bem Ouro+</a> é o programa de apoio a saúde da Pague Menos Extrafarma. Com ele, você terá direito a teleconsultas 24h por dia, com clínico geral, psicólogo e nutricionista. Além de serviços farmacêuticos gratuitos e muito mais.</span>
                                                : 
                                                (message.role == 'appointmentpoc')?
                                                    <span>Você ganhou <strong>50% de desconto</strong> na consulta por whatsapp com <strong>médico da USP</strong>. 
                                                        <br/><br/>De R$19,90 por <strong>R$9,90</strong>.<a href="https://mpago.la/1fpJRgu" target="_blank" onClick={() => ReactGA.event({ category: "IA conversacional", action: "IA - clicou em pagar consulta por texto"})}> CLIQUE AQUI</a> para ser atendido agora.
                                                    </span>
                                                    : <span className="content" dangerouslySetInnerHTML={{__html: message.content}}></span>
                                                
                                            }
                                        </div>
                                    )}
                                    <div className={(this.state.isRecommendingDoctor)? 'display-none' : ''}>
                                        <div className={((this.state.hasSentMessage && !this.state.chatStatus) || this.state.isLoadingProductsList)? 'message-pattern message-type-assistant' : 'display-none'}>
                                            <strong>Balto:  </strong>
                                            <span className={this.state.isTyping? 'typing' : 'display-none'}>Digitando</span>
                                            <TypeAnimation
                                              sequence={[
                                                '.',
                                                500,
                                                '..',
                                                500,
                                                '...',
                                                500
                                              ]}
                                              wrapper="span"
                                              speed={10}
                                              style={{ fontSize: '16px', display: 'inline-block' }}
                                              repeat={Infinity}
                                              cursor={false}
                                            />
                                        </div>
                                    </div>
                                    {(this.state.conditionsArray.length > 0 && !this.state.hasOneCondition)?
                                        <div className='conditions-array-container'>
                                            <div className="message-pattern message-type-assistant">
                                                <strong>Balto:  </strong>
                                                <span className="content">Para ajudar no tratamento eu preciso saber quais condições o cliente possui. Selecione as opções abaixo.</span>
                                            </div>
                                            {this.state.conditionsArray.map((conditionObj, index) => (
                                                <div key={"condition-item-" + index} className="message-pattern message-type-assistant message-type-condition">
                                                    <strong>Balto:  </strong>
                                                    <span>O medicamento {conditionObj.productName} trata as seguintes condições:</span>
                                                    <div className='condition-buttons-container'>
                                                        <ol className="condition-button">
                                                            {(conditionObj.conditions.split(',')).map((condition, conditionIndex) => (
                                                                <li key={'condition-button' + conditionIndex} className={(this.state.selectedConditionsArray[index.toString() + conditionIndex.toString()] && this.state.selectedConditionsArray[index.toString() + conditionIndex.toString()].isChecked)? 'checked-condition' : ''}>
                                                                    <article onClick={(e) => this.selectCondition(e, condition, index, conditionIndex)}>
                                                                        <span>{condition.charAt(0).toUpperCase() + condition.slice(1).toLowerCase()}</span>
                                                                    </article>
                                                                </li>
                                                            ))}
                                                        </ol>
                                                    </div>
                                                </div>
                                            ))}
                                            <ol className={(this.state.symptomsArray.length == 0 && !this.state.hasSearchedSymptoms)? "show-symptoms" : "display-none"}>
                                                <li>
                                                    <article onClick={(e) => this.getSymptoms(e)}>
                                                        <span>Ver sintomas</span>
                                                    </article>
                                                </li>
                                            </ol>
                                        </div>
                                        : ""
                                    }
                                    {(this.state.symptomsArray.length > 0)?
                                        <div className='conditions-array-container'>
                                            <div className="message-pattern message-type-assistant">
                                                <strong>Balto:  </strong>
                                                <span className="content">Por favor, selecione os sintomas que o cliente possui.</span>
                                            </div>
                                            {this.state.symptomsArray.map((symptomObj, index) => (
                                                <div key={"condition-item-" + index} className="message-pattern message-type-assistant message-type-condition">
                                                    <strong>Balto:  </strong>
                                                    {(symptomObj.diagnosis == '')?
                                                        ""
                                                        : <span>A condição <strong>{symptomObj.diagnosis}</strong> está relacionado aos seguintes sintomas:</span>
                                                    }
                                                    <div className='condition-buttons-container'>
                                                        <ol className="condition-button">
                                                            {(symptomObj.symptoms.split(',')).map((symptom, symptomIndex) => (
                                                                <li key={'condition-button' + symptomIndex} className={(this.state.selectedSymptomsArray[index.toString() + symptomIndex.toString()] && this.state.selectedSymptomsArray[index.toString() + symptomIndex.toString()].isChecked)? 'checked-condition' : ''}>
                                                                    <article onClick={(e) => this.selectSymptom(e, symptom, index, symptomIndex)}>
                                                                        <span>{symptom.charAt(0).toUpperCase() + symptom.slice(1).toLowerCase()}</span>
                                                                    </article>
                                                                </li>
                                                            ))}
                                                        </ol>
                                                    </div>
                                                </div>
                                            ))}
                                            {(this.state.mockedAnamnesis)?
                                                <ol className={(this.state.basketArray.length == 0)? "show-symptoms" : "display-none"}>
                                                    <li>
                                                        {(this.state.mockedAnamnesisShowBasket)?
                                                            <article onClick={(e) => this.showNextQuestion(e)}>
                                                                <span>Montar cesta</span>
                                                            </article>
                                                            :
                                                            <article onClick={(e) => this.showNextQuestion(e)}>
                                                                <span>Continuar</span>
                                                            </article>
                                                        }
                                                    </li>
                                                </ol>
                                                : <ol className={(this.state.basketArray.length == 0)? "show-symptoms" : "display-none"}>
                                                    <li>
                                                        <article onClick={(e) => this.getBasket(e)}>
                                                            <span>Montar cesta</span>
                                                        </article>
                                                    </li>
                                                </ol>
                                            }
                                        </div>
                                        : ""
                                    }
                                    {(this.state.symptomsArrayClean.length > 0)?
                                        <div className='conditions-array-container'>
                                            <div className="message-pattern message-type-assistant message-type-condition">
                                                <strong>Balto:  </strong>
                                                <span className="content">Por favor, selecione os sintomas que o cliente possui.</span>
                                                <div className='condition-buttons-container'>
                                                    <ol className="condition-button">
                                                        {this.state.symptomsArrayClean.map((symptom, index) => (
                                                            <li key={'condition-button' + index} className={(this.state.selectedSymptomsArray[index.toString() + '0'] && this.state.selectedSymptomsArray[index.toString() + '0'].isChecked)? 'checked-condition' : ''}>
                                                                <article onClick={(e) => this.selectSymptom(e, symptom.symptom, index, '0')}>
                                                                    <span>{symptom.symptom.charAt(0).toUpperCase() + symptom.symptom.slice(1).toLowerCase()}</span>
                                                                </article>
                                                            </li>
                                                        ))}
                                                    </ol>
                                                </div>
                                            </div>
                                            <ol className={(this.state.basketArray.length == 0)? "show-symptoms" : "display-none"}>
                                                <li>
                                                    <article onClick={(e) => this.getBasket(e)}>
                                                        <span>Montar cesta</span>
                                                    </article>
                                                </li>
                                            </ol>
                                        </div>
                                        : ""
                                    }
                                    {(this.state.basketArray.length > 0)?
                                        <div className='basket-array-container'>
                                            {basketElements}
                                        </div>
                                        : ""
                                    }
                                    <div ref={this.myRef}></div>

                                    {(this.state.combo.price)?
                                        <div className='combo-container'>
                                            <div className='combo-products'><strong>Promoção contendo:</strong></div>
                                            <div className='explanation-container'>
                                                {this.state.combo.explanation.map((explanation, explanationIndex) => (
                                                    <div id={"explanation-item-" + explanationIndex} key={explanationIndex} className='combo-products-explanation'>
                                                    - <strong>{(explanation.split(" "))[0]}</strong> {explanation.substr(explanation.indexOf(" ") + 1)}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="combo-image-container">
                                                <img className="combo-image" src={comboImage}/>
                                            </div>
                                            <div className='combo-price'>R$ {this.state.combo.price}</div>
                                            {(this.state.comboInCart > 0)?
                                                <div className='product-quantity-container'>
                                                    <span onClick={() => this.handleComboCart(false)} className="product-quantity-icon first-icon">-</span>
                                                    <span className="product-quantity">{this.state.comboInCart}</span>
                                                    <span onClick={() => this.handleComboCart(true)} className="product-quantity-icon last-icon">+</span>
                                                </div>
                                                :
                                                <Button onClick={() => this.handleComboCart(true)} className='identifier-button drug-button'>ADICIONAR</Button>
                                            }
                                            {(this.state.isClientCustomerFacing && this.state.clientModule != '')?
                                                <div>
                                                    <a href={'https://www.extrafarma.com.br/checkout/?orderFormId=' + this.state.cartId + '#/cart'} target="_blank" className={(this.state.comboInCart > 0)? 'checkout-button continue-payment' : 'display-none'} onClick={() => ReactGA.event({category: "IA conversacional",action: "IA - seguiu para pagamento",label: `IA - id do carrinho ${this.state.cartId}`, value: this.state.cartTicket})}>SEGUIR PARA PAGAMENTO</a>
                                                </div>
                                                : <div></div>
                                            }
                                        </div>
                                        : <div></div>
                                    }


                                    {(!this.state.isClientDigital)?
                                        <div className="container-striper-container">
                                            {(this.state.productsList && this.state.productsList.length > 0)?
                                                this.state.productsList.map((symptom, symptomIndex) => (
                                                (symptomIndex == 0 || this.state.productsList[symptomIndex - 1].symptom != symptom.symptom)?
                                                    <div className="striped-product-container" id={"symptom-item-" + symptomIndex} key={symptomIndex} >
                                                        {(symptom.symptom == '-')?
                                                            <div className="list-drug-title">Para tratamento da <u><strong>{this.state.diagnosis}</strong></u>:</div>
                                                            : <div className="list-drug-title">Para tratamento do sintoma <u><strong>{symptom.symptom}</strong></u>:</div>
                                                        }
                                                        <Table className="product-container">
                                                            <thead>
                                                                <tr>
                                                                    <th>Nome do produto</th>
                                                                    <th className={(this.state.clientId == 11 || this.state.clientId == 12)? "display-none": ""}>Preço</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.productsList.map((product, productIndex) => (
                                                                    (this.state.productsList[productIndex].symptom == this.state.productsList[symptomIndex].symptom)?
                                                                        <tr key={symptomIndex + productIndex}>
                                                                            <td>{product.productName}</td>
                                                                            <td className={(this.state.clientId == 11 || this.state.clientId == 12)? "display-none": ""}>R${product.price}</td>
                                                                        </tr>
                                                                        : ""
                                                                    
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    : ""
                                            ))
                                            : ""
                                            }
                                        </div>
                                        :
                                        <div>
                                            <div className="list-drugs-web">
                                                {this.state.productsList.map((symptom, symptomIndex) => (
                                                    (symptom.products[0] == 'Procurar médico')?
                                                        <div></div>
                                                        :
                                                        <div id={"symptom-item-" + symptomIndex} key={symptomIndex} >
                                                            <div className="list-drug-title">Para tratamento do sintoma <strong>{symptom.symptom}</strong>:</div>
                                                            <div className="slider-container slider-container-web">
                                                                <Swiper 
                                                                    onSlideChange={(e) => this.swipeProduct(e, true, symptomIndex)}
                                                                    slidesPerView={4}
                                                                    spaceBetween={30}
                                                                    pagination={{
                                                                        clickable: true,
                                                                    }}
                                                                    navigation={true}
                                                                    modules={[Pagination, Navigation]}
                                                                    className="mySwiper"
                                                                    >
                                                                    {symptom.products.map((product, productIndex) => (
                                                                        <SwiperSlide key={productIndex}>
                                                                            <div className="drug-image-container"><img className="drug-image" src={product.items[0].images[0].imageUrl}/></div>
                                                                            <span className='drug-name'>{product.productTitle}</span>
                                                                            {(this.state.isClientCustomerFacing)?
                                                                                <div className='drug-price'>R$ {(product.items[0].sellers[0].commertialOffer.Price).toString().replace(".", ",")}</div>
                                                                                : <div></div>
                                                                            }

                                                                            {(this.state.isClientCustomerFacing)?
                                                                                ((this.state.cartList[(productIndex.toString() + symptomIndex.toString())]) && this.state.cartList[(productIndex.toString() + symptomIndex.toString())].quantity > 0)?
                                                                                    <div className='product-quantity-container'>
                                                                                        <span onClick={() => this.handleCart((productIndex.toString() + symptomIndex.toString()), product.items[0].sellers[0].commertialOffer.Price, product.items[0].itemId, false)} className="product-quantity-icon first-icon">-</span>
                                                                                        <span className="product-quantity">{this.state.cartList[(productIndex.toString() + symptomIndex.toString())].quantity}</span>
                                                                                        <span onClick={() => this.handleCart((productIndex.toString() + symptomIndex.toString()), product.items[0].sellers[0].commertialOffer.Price, product.items[0].itemId, true)} className="product-quantity-icon last-icon">+</span>
                                                                                    </div>
                                                                                    :
                                                                                    <Button onClick={() => this.handleCart((productIndex.toString() + symptomIndex.toString()), product.items[0].sellers[0].commertialOffer.Price, product.items[0].itemId, true)} className='identifier-button drug-button'>ADICIONAR</Button>
                                                                                : <div></div>
                                                                            }
                                                                        </SwiperSlide>
                                                                    ))}
                                                                </Swiper>
                                                            </div>
                                                        </div>
                                                ))}
                                            </div>
                                            <div className="list-drugs-mobile">
                                                {this.state.productsList.map((symptom, symptomIndex) => (
                                                    (symptom.products[0] == 'Procurar médico')?
                                                        <div></div>
                                                        :
                                                        <div id={"symptom-item-" + symptomIndex} key={symptomIndex} >
                                                            <div className="list-drug-title">Para tratamento do sintoma <strong>{symptom.symptom}</strong>:</div>
                                                            <div className="slider-container slider-container-web">
                                                                <Swiper 
                                                                    onSlideChange={(e) => this.swipeProduct(e, true, symptomIndex)}
                                                                    slidesPerView={1}
                                                                    spaceBetween={30}
                                                                    pagination={{
                                                                        clickable: true,
                                                                    }}
                                                                    navigation={true}
                                                                    modules={[Pagination, Navigation]}
                                                                    className="mySwiper"
                                                                    >
                                                                    {symptom.products.map((product, productIndex) => (
                                                                        <SwiperSlide key={productIndex}>
                                                                            <div className="drug-image-container"><img className="drug-image" src={product.items[0].images[0].imageUrl}/></div>
                                                                            <span className='drug-name'>{product.productTitle}</span>
                                                                            {(this.state.isClientCustomerFacing)?
                                                                                <div className='drug-price'>R$ {(product.items[0].sellers[0].commertialOffer.Price).toString().replace(".", ",")}</div>
                                                                                : <div></div>
                                                                            }

                                                                            {(this.state.isClientCustomerFacing)?
                                                                                ((this.state.cartList[(productIndex.toString() + symptomIndex.toString())]) && this.state.cartList[(productIndex.toString() + symptomIndex.toString())].quantity > 0)?
                                                                                    <div className='product-quantity-container'>
                                                                                        <span onClick={() => this.handleCart((productIndex.toString() + symptomIndex.toString()), product.items[0].sellers[0].commertialOffer.Price, product.items[0].itemId, false)} className="product-quantity-icon first-icon">-</span>
                                                                                        <span className="product-quantity">{this.state.cartList[(productIndex.toString() + symptomIndex.toString())].quantity}</span>
                                                                                        <span onClick={() => this.handleCart((productIndex.toString() + symptomIndex.toString()), product.items[0].sellers[0].commertialOffer.Price, product.items[0].itemId, true)} className="product-quantity-icon last-icon">+</span>
                                                                                    </div>
                                                                                    :
                                                                                    <Button onClick={() => this.handleCart((productIndex.toString() + symptomIndex.toString()), product.items[0].sellers[0].commertialOffer.Price, product.items[0].itemId, true)} className='identifier-button drug-button'>ADICIONAR</Button>
                                                                                : <div></div>
                                                                            }
                                                                        </SwiperSlide>
                                                                    ))}
                                                                </Swiper>
                                                            </div>
                                                        </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {(this.state.secondaryProductsList.length > 0)?
                                        <div>
                                            <div className='list-drug-title'>Outros produtos para você:</div>
                                            <Swiper 
                                                onSlideChange={(e) => this.swipeProduct(e, false, 0)}
                                                slidesPerView={1}
                                                spaceBetween={30}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                navigation={true}
                                                modules={[Pagination, Navigation]}
                                                className="mySwiper"
                                                >
                                                {this.state.secondaryProductsList.map((symptom, symptomIndex) => (
                                                    symptom.products.map((product, productIndex) => (
                                                        <SwiperSlide key={productIndex}>
                                                            <div className="drug-image-container"><img className="drug-image" src={(product.items)? product.items[0].images[0].imageUrl : product.image}/></div>
                                                            <span className='drug-name'>{(product.productTitle)? product.productTitle : product.title}</span>
                                                            {(this.state.isClientCustomerFacing && this.state.clientModule != '')?
                                                                <div className='drug-price'>R$ {(product.items[0].sellers[0].commertialOffer.Price).toString().replace(".", ",")}</div>
                                                                : <div></div>
                                                            }
                                                            
                                                            {(this.state.isClientCustomerFacing && this.state.clientModule != '')?
                                                                ((this.state.cartList[('x' + productIndex.toString())]) && this.state.cartList[('x' + productIndex.toString())].quantity > 0)?
                                                                    <div className='product-quantity-container'>
                                                                        <span onClick={() => this.handleCart(('x' + productIndex.toString()),product.items[0].sellers[0].commertialOffer.Price , product.items[0].itemId, false)} className="product-quantity-icon first-icon">-</span>
                                                                        <span className="product-quantity">{this.state.cartList[('x' + productIndex.toString())].quantity}</span>
                                                                        <span onClick={() => this.handleCart(('x' + productIndex.toString()),product.items[0].sellers[0].commertialOffer.Price , product.items[0].itemId, true)} className="product-quantity-icon last-icon">+</span>
                                                                    </div>
                                                                    :
                                                                    <Button onClick={() => this.handleCart(('x' + productIndex.toString()),product.items[0].sellers[0].commertialOffer.Price , product.items[0].itemId, true)} className='identifier-button drug-button'>ADICIONAR</Button>
                                                                : <div></div>
                                                            }
                                                        </SwiperSlide>
                                                    ))
                                                ))}
                                            </Swiper>
                                        </div>
                                        :<div></div>
                                    }
                                    {(this.state.needDoctorSymptoms.length == 0)?
                                        <div></div>
                                        :
                                        <div>
                                            <div className="message-pattern message-type-assistant">
                                                <strong>Balto:  </strong> Para o(s) sintoma(s)
                                                {this.state.needDoctorSymptoms.map((symptom, symptomIndex) => (
                                                    <span key={symptomIndex}><strong> {symptom}</strong> e </span>
                                                ))}
                                                seu contexto de saúde é importante você realize uma consulta médica
                                            </div>
                                        </div>
                                    }

                                    {(this.state.isClientCustomerFacing && this.state.productsList.length > 0)?
                                        <div>
                                            <a href={'https://www.extrafarma.com.br/checkout/?orderFormId=' + this.state.cartId + '#/cart'} target="_blank" className={(Object.keys(this.state.cartList).length > 0)? 'checkout-button continue-payment' : 'display-none'} onClick={() => ReactGA.event({category: "IA conversacional",action: "IA - seguiu para pagamento",label: `IA - id do carrinho ${this.state.cartId}`})}>SEGUIR PARA PAGAMENTO</a>
                                        </div>
                                        : <div></div>
                                    }
                                </div>
                            </div>
                            <div className={(this.state.showSearchConditionPage)? 'display-none': ((this.state.isPlatformWeb) ? '' : 'mobile-class')}>
                                <div className={(this.state.hasEndedChat) ? 'display-none' : 'first-container-main'}>
                                    <div className="first-left-container">
                                        {(this.state.hasLoggedIn)?
                                            (this.state.clientId != 1 && !this.state.hasStartedAnamnese)?
                                                <div className='balto-search-container'>
                                                    <InstantSearch searchClient={searchClient} indexName="balto_prod" >
                                                        <SearchBox
                                                            autoFocus={true}
                                                            className='balto-searchbox'
                                                            onSubmit={(e) => this.onSubmitAlgolia(e)}
                                                            value={this.state.query} />
                                                        <div className='balto-hits-container'>
                                                            {(this.state.showSeeBasket && this.state.conditionsArray.length == 0)?
                                                                <ol>
                                                                    <li>
                                                                        <article className="show-basket" onClick={(e) => this.getConditions(e)}>
                                                                            <span>Ver cesta</span>
                                                                        </article>
                                                                    </li>
                                                                </ol>
                                                                : ""
                                                            }
                                                            <Index indexName="balto_prod" className='balto-hits'>
                                                                <Hits hitComponent={this.Hit} />
                                                            </Index>
                                                        </div>
                                                    </InstantSearch>
                                                </div>
                                                :
                                                <Form onSubmit={(e) => this.postChat(e)} className="chat-form">
                                                    <Form.Group controlId="chat" className="inline-input">
                                                        <Form.Control
                                                            ref={this.textLog1}
                                                            className={(this.state.chatStatus)? "inline-textarea" : 'display-none'}
                                                            as="textarea"
                                                            onKeyDown={this.onEnterPress}
                                                            onChange={(e) => this.inputChatChange(e, 'messageSent')}
                                                            autoFocus
                                                            name="chat"
                                                            value={this.state.messageSent} />
                                                        <Form.Control
                                                            ref={this.textLog}
                                                            className={(this.state.chatStatus)? "display-none" : 'inline-textarea'}
                                                            as="textarea"
                                                            onKeyDown={this.onEnterPress}
                                                            onChange={(e) => this.inputChatChange(e, 'messageSent')}
                                                            disabled
                                                            name="chat"
                                                            value={this.state.messageSent} />
                                                    </Form.Group>
                                                    {this.state.hasEndedChat ?
                                                        <div></div>
                                                        : <Button className={"submit-form " + (this.state.chatStatus? '' : 'input-disable')} type="submit">
                                                            <img className="icon-enviar" src={IconEnviarImg}/>
                                                        </Button>
                                                    }
                                                </Form>
                                            : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.showSearchConditionPage)? 
                            <div className="search-condition-page">
                                <Form onSubmit={(e) => this.setIdentifier(e)} className={(this.state.productsList.length != 0)? 'display-none' : "condition-searched-form"}>
                                    <Form.Group controlId="conditionSearched" className="condition-searched-input">
                                        <Form.Label>Busque pela condição</Form.Label>
                                        <Form.Control type="text"
                                            onChange={(e) => this.searchDiagnosis(e)}
                                            placeholder="Ex: Dengue"
                                        />
                                    </Form.Group>
                                </Form>

                                <div className={(this.state.productsList.length != 0)? 'display-none' : "diagnosis-list"}>
                                    {this.state.filteredConditionsList.map((diagnosis, index) =>
                                        <div onClick={() => this.getProductsFromDiagnosis(index)} key={index} className='diagnosis-option'>{diagnosis.diagnosis}</div>
                                    )}
                                    <br/>
                                </div>

                                <div className="container-striper-container">
                                    {(this.state.productsList && this.state.productsList.length > 0)?
                                        this.state.productsList.map((symptom, symptomIndex) => (
                                        (symptomIndex == 0 || this.state.productsList[symptomIndex - 1].symptom != symptom.symptom)?
                                            <div className="striped-product-container" id={"symptom-item-" + symptomIndex} key={symptomIndex} >
                                                {(symptom.symptom == '-')?
                                                    <div className="list-drug-title">Para tratamento da <u><strong>{this.state.diagnosis}</strong></u>:</div>
                                                    : <div className="list-drug-title">Para tratamento do sintoma <u><strong>{symptom.symptom}</strong></u>:</div>
                                                }
                                                <Table className="product-container">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome do produto</th>
                                                            <th className={(this.state.clientId == 11 || this.state.clientId == 12)? "display-none": ""}>Preço</th>
                                                            <th className={(this.state.clientId == 11 || this.state.clientId == 12)? "display-none": ""}>Lucro</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.productsList.map((product, productIndex) => (
                                                            (this.state.productsList[productIndex].symptom == this.state.productsList[symptomIndex].symptom)?
                                                                <tr key={symptomIndex + productIndex}>
                                                                    <td>{product.productName}</td>
                                                                    <td className={(this.state.clientId == 11 || this.state.clientId == 12)? "display-none": ""}>R${product.price}</td>
                                                                    <td className={(this.state.clientId == 11 || this.state.clientId == 12)? "display-none": ""}>R${product.margin}</td>
                                                                </tr>
                                                                : ""
                                                            
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : ""
                                    ))
                                    : ""
                                    }
                                </div>
                            </div>
                            : ""
                        }
                    </section>
                }
                <div ref={this.myRefMobile}></div>
            </div>
        )
    }
}

export default Web;




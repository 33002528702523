import axios from 'axios';
import config from  '../config/config.json';

export const postChat = (messages, clientToken, clientModule) => {
    const env = process.env.NODE_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/chat?client-token=${clientToken}`, messages, {
        'headers': {
            "Client-Module": clientModule
        }
    });
}

export const getSecondaryProducts = (chatId, clientModule) => {
    const env = process.env.NODE_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/chat/secondary-products`,
        {
            "chatId": chatId
        }, {
        'headers': {
            "Client-Module": clientModule
        }});
}

export const getPrimaryProducts = (clientId, clientModule, id) => {
    const env = process.env.NODE_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;
    return axios.post(`${apiUrl}/chat/primary-products`,
        {
            "id": id
        }, {
        'headers': {
            "Client-Module": clientModule,
            "client-id": clientId
        }});
}

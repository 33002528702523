import axios from 'axios';
import config from  '../config/config.json';

export const postOnDataLake = (eventName, currentUrl, properties) => {
    const env = process.env.NODE_ENV || 'development';
    const apiUrl = config[env].REACT_APP_API_URL;

    const postObj = {
        userId: (window.localStorage.getItem('jet7-client-id') === null && window.Android)? window.Android.getUserId() : window.localStorage.getItem('jet7-client-id'),
        latitude: (window.localStorage.getItem('jet7-client-latitude') === null && window.Android)? window.Android.getUserLatitude() : window.localStorage.getItem('jet7-client-latitude'),
        longitude: (window.localStorage.getItem('jet7-client-longitude') === null && window.Android)? window.Android.getUserLongitude() : window.localStorage.getItem('jet7-client-longitude'),
        properties: properties,
        currentUrl: currentUrl,
        eventName: eventName
    };
    
    axios.post(`${apiUrl}/data-lake-user-events`, postObj)
}

// Alterar para localstorage da Saúde 360
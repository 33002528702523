import axios from 'axios';
import config from  '../config/config.json';
import emailjs from '@emailjs/browser';

export const sendEmail = (companyName) => {
    const env = process.env.NODE_ENV || 'development';
    return emailjs.send(config[env].emailjs.SERVICE_ID,
        config[env].emailjs.TEMPLATE_ID,
        { companyName: companyName },
        config[env].emailjs.PUBLIC_KEY).then(
            function (response) {
                console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
                console.log("FAILED...", error);
            }
        );
}